var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tAllInspect" },
    [
      _c(
        "Row",
        { staticStyle: { "flex-wrap": "nowrap" }, attrs: { gutter: 4 } },
        [
          _vm.outExpand
            ? _c(
                "Col",
                { style: _vm.outLeftSpan },
                [
                  _c(
                    "Card",
                    { staticClass: "card" },
                    [
                      _c(
                        "Row",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "RadioGroup",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                width: "80%",
                              },
                              on: { "on-change": _vm.statusChange },
                              model: {
                                value: _vm.status,
                                callback: function ($$v) {
                                  _vm.status = $$v
                                },
                                expression: "status",
                              },
                            },
                            [
                              _c("Radio", { attrs: { label: "在检" } }),
                              _c("Radio", { attrs: { label: "待总检" } }),
                              _c("Radio", { attrs: { label: "已总检" } }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "20%",
                                "text-align": "right",
                              },
                            },
                            [
                              _c(
                                "i-switch",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { size: "large" },
                                  on: { "on-change": _vm.typeChange },
                                  model: {
                                    value: _vm.typeStatus,
                                    callback: function ($$v) {
                                      _vm.typeStatus = $$v
                                    },
                                    expression: "typeStatus",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "open" }, slot: "open" },
                                    [_vm._v("复查")]
                                  ),
                                  _c(
                                    "span",
                                    { attrs: { slot: "close" }, slot: "close" },
                                    [_vm._v("非复查")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.queryTime
                        ? _c(
                            "RadioGroup",
                            {
                              staticClass: "btngroup",
                              attrs: {
                                type: "button",
                                size: "small",
                                "button-style": "solid",
                              },
                              on: { "on-change": _vm.pick },
                              model: {
                                value: _vm.date,
                                callback: function ($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date",
                              },
                            },
                            [
                              _c("Radio", { attrs: { label: "当日" } }),
                              _c("Radio", { attrs: { label: "当月" } }),
                              _c(
                                "DatePicker",
                                {
                                  attrs: {
                                    open: _vm.datePickerShow,
                                    transfer: "",
                                    type: "daterange",
                                  },
                                  on: { "on-change": _vm.datePickerChange },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      [
                                        _c("Radio", {
                                          attrs: { label: "自定义" },
                                          nativeOn: {
                                            click: function ($event) {
                                              _vm.datePickerShow =
                                                !_vm.datePickerShow
                                            },
                                          },
                                        }),
                                      ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _c("Radio", {
                                staticStyle: { cursor: "pointer" },
                                attrs: { label: "重置" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.queryTime
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "nowrap",
                                "align-items": "center",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c("DatePicker", {
                                attrs: {
                                  type: "date",
                                  transfer: "",
                                  placeholder: "开始时间",
                                },
                                on: { "on-change": _vm.datePickerSeacTime },
                                model: {
                                  value: _vm.personParam.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.personParam, "startDate", $$v)
                                  },
                                  expression: "personParam.startDate",
                                },
                              }),
                              _vm._v("  ~  "),
                              _c("DatePicker", {
                                attrs: {
                                  type: "date",
                                  transfer: "",
                                  placeholder: "结束时间",
                                },
                                on: { "on-change": _vm.datePickerEndTime },
                                model: {
                                  value: _vm.personParam.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.personParam, "endDate", $$v)
                                  },
                                  expression: "personParam.endDate",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "Row",
                        {
                          staticStyle: { display: "flex", "flex-wrap": "wrap" },
                        },
                        [
                          _c("Input", {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { placeholder: "请输入姓名", clearable: "" },
                            on: {
                              "on-clear": function ($event) {
                                return _vm.inputSearchChange("")
                              },
                            },
                            nativeOn: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.inputSearchChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.personParam.personName,
                              callback: function ($$v) {
                                _vm.$set(_vm.personParam, "personName", $$v)
                              },
                              expression: "personParam.personName",
                            },
                          }),
                          _vm.drop
                            ? _c("Input", {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: {
                                  placeholder: "请输入体检编号",
                                  clearable: "",
                                },
                                on: {
                                  "on-clear": function ($event) {
                                    return _vm.inputSearchChange("")
                                  },
                                },
                                nativeOn: {
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.inputSearchChange.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.personParam.testNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.personParam, "testNum", $$v)
                                  },
                                  expression: "personParam.testNum",
                                },
                              })
                            : _vm._e(),
                          _vm.drop
                            ? _c("Input", {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: {
                                  placeholder: "请输入单位名称",
                                  clearable: "",
                                },
                                on: {
                                  "on-clear": function ($event) {
                                    return _vm.inputSearchChange("")
                                  },
                                },
                                nativeOn: {
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.inputSearchChange.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.personParam.dept,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.personParam, "dept", $$v)
                                  },
                                  expression: "personParam.dept",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "Button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.inputSearchChange },
                            },
                            [_vm._v("查询")]
                          ),
                          !_vm.queryTime
                            ? _c(
                                "Button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetSearchForm("重置")
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              )
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass: "drop-down",
                              on: { click: _vm.dropDown },
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.dropDownContent) + " "),
                              _c("Icon", { attrs: { type: _vm.dropDownIcon } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        {
                          staticStyle: { "justify-content": "center" },
                          style: { height: "calc(100% - " + _vm.height1 + ")" },
                        },
                        [
                          _vm.personList.length > 0
                            ? _c(
                                "RadioGroup",
                                {
                                  staticClass: "radio-group",
                                  model: {
                                    value: _vm.radioValue,
                                    callback: function ($$v) {
                                      _vm.radioValue = $$v
                                    },
                                    expression: "radioValue",
                                  },
                                },
                                _vm._l(_vm.personList, function (item, index) {
                                  return _c(
                                    "Row",
                                    {
                                      key: index,
                                      staticClass: "row-border",
                                      staticStyle: { "margin-left": "15px" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.radioChooseClick(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "radio-group-radio",
                                          attrs: { span: 4 },
                                        },
                                        [
                                          _c("Radio", {
                                            staticClass: "label",
                                            attrs: {
                                              label: item.id,
                                              disabled: _vm.radioShow,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "radio-group-content",
                                          attrs: { span: 15 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "80px",
                                                overflow: "hidden",
                                                "text-overflow": "ellipsis",
                                                "white-space": "nowrap",
                                              },
                                              attrs: { title: item.personName },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.personName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.sex))]
                                          ),
                                          _c("div", [_vm._v(_vm._s(item.age))]),
                                        ]
                                      ),
                                      _c(
                                        "Col",
                                        {
                                          staticStyle: { "padding-left": "0" },
                                          attrs: { span: 5 },
                                        },
                                        [
                                          _c(
                                            "Row",
                                            { attrs: { gutter: 6 } },
                                            [
                                              _c(
                                                "Col",
                                                { attrs: { span: 12 } },
                                                [
                                                  item.physicalType &&
                                                  item.physicalType.indexOf(
                                                    "职业"
                                                  ) != -1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-blue",
                                                        },
                                                        [_vm._v(" 职 ")]
                                                      )
                                                    : _vm._e(),
                                                  item.physicalType &&
                                                  item.physicalType.indexOf(
                                                    "健康"
                                                  ) != -1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-green",
                                                        },
                                                        [_vm._v(" 健 ")]
                                                      )
                                                    : _vm._e(),
                                                  item.physicalType &&
                                                  item.physicalType.indexOf(
                                                    "从业"
                                                  ) != -1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-yellow",
                                                        },
                                                        [_vm._v(" 从 ")]
                                                      )
                                                    : _vm._e(),
                                                  item.physicalType.indexOf(
                                                    "放射"
                                                  ) != -1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-purple",
                                                        },
                                                        [_vm._v(" 放 ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: 12 } },
                                                [
                                                  item.sporadicPhysical &&
                                                  item.sporadicPhysical == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-purple",
                                                        },
                                                        [_vm._v(" 零 ")]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border-purple",
                                                        },
                                                        [_vm._v(" 团 ")]
                                                      ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm.personList.length < 1 ? _c("no-data") : _vm._e(),
                          _vm.checkPersonLoading
                            ? _c(
                                "Spin",
                                { attrs: { fix: "" } },
                                [
                                  _c("Icon", {
                                    staticClass: "demo-spin-icon-load",
                                    attrs: { type: "ios-loading", size: "18" },
                                  }),
                                  _c("div", [_vm._v("加载中...")]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.personList.length > 0
                            ? _c("Page", {
                                attrs: {
                                  current: _vm.personParam.pageNumber,
                                  total: _vm.personTotal,
                                  simple: "",
                                },
                                on: { "on-change": _vm.personChangePageNum },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "expand" },
            [
              _c("Icon", {
                staticClass: "icon",
                attrs: { type: _vm.outExpandIcon, size: "16" },
                on: { click: _vm.outChangeExpand },
              }),
            ],
            1
          ),
          _vm.personInfo.id
            ? _c(
                "Col",
                {
                  style: _vm.outSpan,
                  attrs: { height: "calc(100vh - 128px)" },
                },
                [
                  _vm.ShutDown
                    ? _c(
                        "Card",
                        [
                          _c(
                            "Form",
                            [
                              _c(
                                "Row",
                                {
                                  staticStyle: { "flex-wrap": "nowrap" },
                                  attrs: { gutter: 10 },
                                },
                                [
                                  _c(
                                    "Col",
                                    {
                                      staticStyle: { "min-width": "160px" },
                                      attrs: { span: "3" },
                                    },
                                    [
                                      _c("div", { staticClass: "pic" }, [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "viewer",
                                                rawName: "v-viewer",
                                                value: { movable: false },
                                                expression: "{movable: false}",
                                              },
                                            ],
                                            staticClass: "pic-box",
                                            style: {
                                              border: _vm.personInfo.avatar
                                                ? "0 !important;"
                                                : "auto",
                                            },
                                          },
                                          [
                                            _vm.personInfo.avatar
                                              ? _c("img", {
                                                  staticStyle: {
                                                    height: "100%",
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    src: _vm.srcImage(
                                                      _vm.personInfo.avatar
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            !_vm.personInfo.avatar
                                              ? _c("img", {
                                                  staticStyle: {
                                                    height: "100%",
                                                    width: "100%",
                                                    padding: "5px",
                                                  },
                                                  attrs: {
                                                    src: require("../../../assets/user-avatar.png"),
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "Col",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        "justify-content": "space-between",
                                      },
                                      attrs: { span: "21" },
                                    },
                                    [
                                      _c(
                                        "Row",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c("Col", { attrs: { span: "24" } }, [
                                            _c(
                                              "div",
                                              { staticClass: "wrap" },
                                              [
                                                _c("step", {
                                                  attrs: {
                                                    index: _vm.currentIndex,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Row",
                                        { staticClass: "txtFrom" },
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "体检编号" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .test_num ||
                                                          _vm.personInfo.testNum
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "身份证号" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .id_card ||
                                                          _vm.personInfo.idCard
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: _vm.setLabel("姓名"),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .person_name ||
                                                          _vm.personInfo
                                                            .personName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: _vm.setLabel("性别"),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo.sex
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Row",
                                        { staticClass: "txtFrom" },
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: _vm.setLabel("年龄"),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo.age
                                                      ) +
                                                      "岁 "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "电话号码" },
                                                },
                                                [
                                                  _c(
                                                    "Tooltip",
                                                    {
                                                      attrs: {
                                                        transfer: "",
                                                        "max-width": "200",
                                                        content:
                                                          _vm.personInfo.mobile,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.personInfo
                                                              .mobile
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "单位名称" },
                                                },
                                                [
                                                  _c(
                                                    "Tooltip",
                                                    {
                                                      attrs: {
                                                        transfer: "",
                                                        "max-width": "200",
                                                        content:
                                                          _vm.personInfo
                                                            .unit_name,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.personInfo
                                                              .unit_name &&
                                                              _vm.personInfo
                                                                .unit_name
                                                                .length > 12
                                                              ? _vm.personInfo.unit_name.substring(
                                                                  0,
                                                                  10
                                                                ) + "..."
                                                              : _vm.personInfo
                                                                  .unit_name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "登记时间" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .regist_date ||
                                                          _vm.personInfo
                                                            .registDate
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.personInfo.physical_type ==
                                        "职业体检" ||
                                      _vm.personInfo.physicalType ==
                                        "职业体检" ||
                                      _vm.personInfo.physical_type ==
                                        "放射体检" ||
                                      _vm.personInfo.physicalType == "放射体检"
                                        ? _c(
                                            "Row",
                                            { staticClass: "txtFrom" },
                                            [
                                              _c(
                                                "Col",
                                                { attrs: { span: 6 } },
                                                [
                                                  _c(
                                                    "FormItem",
                                                    {
                                                      attrs: {
                                                        label: "危害因素",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "Tooltip",
                                                        {
                                                          attrs: {
                                                            transfer: "",
                                                            "max-width": "200",
                                                            content:
                                                              _vm.personInfo
                                                                .hazard_factors_text,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.personInfo
                                                                  .hazard_factors_text ||
                                                                  _vm.personInfo
                                                                    .hazardFactorsText
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: 6 } },
                                                [
                                                  _c(
                                                    "FormItem",
                                                    {
                                                      attrs: {
                                                        label: "检查种类",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.personInfo
                                                              .work_state_text ||
                                                              _vm.personInfo
                                                                .workStateText
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: 6 } },
                                                [
                                                  _c(
                                                    "FormItem",
                                                    {
                                                      attrs: {
                                                        label: "目标职业病",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "Tooltip",
                                                        {
                                                          attrs: {
                                                            transfer: "",
                                                            "max-width": "200",
                                                            content:
                                                              _vm.personInfo
                                                                .occupational_diseases,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.personInfo
                                                                  .occupational_diseases ||
                                                                  _vm.personInfo
                                                                    .occupationalDiseases
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "Col",
                                                { attrs: { span: 6 } },
                                                [
                                                  _c(
                                                    "FormItem",
                                                    {
                                                      attrs: {
                                                        label: "目标职业禁忌证",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "Tooltip",
                                                        {
                                                          attrs: {
                                                            transfer: "",
                                                            "max-width": "200",
                                                            content:
                                                              _vm.personInfo
                                                                .occupational_taboo,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.personInfo
                                                                  .occupational_taboo ||
                                                                  _vm.personInfo
                                                                    .occupationalTaboo
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "margin-top": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "Button",
                                    {
                                      directives: [
                                        {
                                          name: "width",
                                          rawName: "v-width",
                                          value: 150,
                                          expression: "150",
                                        },
                                      ],
                                      attrs: {
                                        type: "primary",
                                        icon: "md-list",
                                      },
                                      on: { click: _vm.resultSummary },
                                    },
                                    [_vm._v("阳性结果汇总")]
                                  ),
                                  _c(
                                    "Button",
                                    {
                                      directives: [
                                        {
                                          name: "width",
                                          rawName: "v-width",
                                          value: 150,
                                          expression: "150",
                                        },
                                      ],
                                      attrs: {
                                        type: "info",
                                        icon: "ios-eye-outline",
                                      },
                                      on: { click: _vm.resultLook },
                                    },
                                    [_vm._v("明细结果查看")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticStyle: { width: "100%", "text-align": "center" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "drop-down",
                          on: { click: _vm.dropDown1 },
                        },
                        [
                          _c("Icon", {
                            attrs: { type: _vm.ShutDownIcon, size: 20 },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "Card",
                    { staticClass: "inspect" },
                    [
                      _c(
                        "Row",
                        {
                          staticStyle: { "margin-bottom": "-10px" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("Col", { attrs: { span: "7" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "Tabs",
                                  { attrs: { value: "name1" } },
                                  [
                                    _c(
                                      "TabPane",
                                      {
                                        attrs: {
                                          label: "危机值提示",
                                          name: "name1",
                                        },
                                      },
                                      [
                                        _c("Table", {
                                          ref: "table",
                                          staticStyle: { "margin-top": "10px" },
                                          attrs: {
                                            loading: _vm.crisisLoading,
                                            height: "385",
                                            border: "",
                                            columns: _vm.crisisColumn,
                                            sortable: "custom",
                                            data: _vm.crisisData,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "TabPane",
                                      {
                                        attrs: {
                                          label: "体检提醒",
                                          name: "name2",
                                        },
                                      },
                                      [
                                        _c("myWangEditor", {
                                          ref: "myWangEditor",
                                          staticClass: "textarea",
                                          attrs: {
                                            id: "editor",
                                            editorContent: _vm.remind,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "TabPane",
                                      {
                                        attrs: {
                                          label: _vm.noInsProject,
                                          name: "name3",
                                        },
                                      },
                                      [
                                        _c("Table", {
                                          ref: "table",
                                          staticStyle: { "margin-top": "10px" },
                                          attrs: {
                                            loading: _vm.noInsProjectLoading,
                                            height: "385",
                                            border: "",
                                            columns: _vm.noInsProjectColumn,
                                            sortable: "custom",
                                            data: _vm.noInsProjectData,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "TabPane",
                                      {
                                        attrs: {
                                          label: _vm.abandonProject,
                                          name: "name4",
                                        },
                                      },
                                      [
                                        _c("Table", {
                                          ref: "abandonTable",
                                          staticStyle: { "margin-top": "10px" },
                                          attrs: {
                                            loading: _vm.abandonProjectLoading,
                                            height: "385",
                                            border: "",
                                            columns: _vm.abandonProjectColumn,
                                            sortable: "custom",
                                            data: _vm.abandonProjectData,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "Col",
                            { attrs: { span: "17" } },
                            [
                              _c(
                                "Row",
                                {
                                  staticClass: "btn-sty",
                                  staticStyle: { "margin-top": "15px" },
                                },
                                [
                                  _c("Col", { attrs: { span: "10" } }),
                                  _c("Col", {
                                    staticClass: "btn",
                                    attrs: { span: "14" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "Row",
                                { attrs: { justify: "end" } },
                                [
                                  _c("Table", {
                                    ref: "table",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      loading: _vm.tableLoading,
                                      border: "",
                                      height: "400",
                                      columns: _vm.columns,
                                      sortable: "custom",
                                      data: _vm.tableData,
                                      "row-class-name": _vm.rowClassName1,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "action",
                                          fn: function ({ row, index }) {
                                            return [
                                              _c(
                                                "Button",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    icon: "ios-eye-outline",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleViewClickAnomaly(
                                                        row,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("查看 ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      246770611
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { gutter: 50 },
                        },
                        [
                          (_vm.personInfo.physical_type == "从业体检" ||
                            _vm.personInfo.physicalType == "从业体检" ||
                            _vm.personInfo.physical_type == "健康体检" ||
                            _vm.personInfo.physicalType == "健康体检") &&
                          _vm.isInspectionChange
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _vm.isReviewResult ||
                                  _vm.personInfo.is_recheck ||
                                  _vm.personInfo.isRecheck
                                    ? _c(
                                        "Button",
                                        {
                                          staticClass: "choice",
                                          staticStyle: {
                                            bottom: "2px",
                                            left: "25px",
                                            width: "65px",
                                            "margin-right": "0px",
                                          },
                                          attrs: { type: "primary" },
                                          on: { click: _vm.clickReviewResult },
                                        },
                                        [_vm._v("复查结论 ")]
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "txt" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "16px",
                                          position: "relative",
                                          top: "4px",
                                        },
                                      },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("体检结果"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "textareaResult" },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { maxRows: 2, minRows: 2 },
                                        },
                                        model: {
                                          value: _vm.inspectForm.conclusion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inspectForm,
                                              "conclusion",
                                              $$v
                                            )
                                          },
                                          expression: "inspectForm.conclusion",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          (_vm.personInfo.physical_type == "从业体检" ||
                            _vm.personInfo.physicalType == "从业体检" ||
                            _vm.personInfo.physical_type == "健康体检" ||
                            _vm.personInfo.physicalType == "健康体检") &&
                          _vm.isInspectionChange
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticClass: "choice",
                                      staticStyle: { width: "65px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleWordChoose("zjzybmc")
                                        },
                                      },
                                    },
                                    [_vm._v("主检结论 ")]
                                  ),
                                  _c("div", { staticClass: "txt" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "16px",
                                          position: "relative",
                                          top: "4px",
                                        },
                                      },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("主检结论"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "textareaResult" },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          type: "textarea",
                                          disabled: true,
                                          autosize: { maxRows: 2, minRows: 2 },
                                        },
                                        model: {
                                          value: _vm.conclusionNow,
                                          callback: function ($$v) {
                                            _vm.conclusionNow = $$v
                                          },
                                          expression: "conclusionNow",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          (_vm.personInfo.physical_type == "从业体检" ||
                            _vm.personInfo.physicalType == "从业体检" ||
                            _vm.personInfo.physical_type == "健康体检" ||
                            _vm.personInfo.physicalType == "健康体检") &&
                          _vm.isInspectionChange
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticClass: "choice",
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleWordChoose("zjclyj")
                                        },
                                      },
                                    },
                                    [_vm._v("选 ")]
                                  ),
                                  _c("div", { staticClass: "txt" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "16px",
                                          position: "relative",
                                          top: "4px",
                                        },
                                      },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("主检建议"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "textareaResult" },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { maxRows: 2, minRows: 2 },
                                        },
                                        model: {
                                          value: _vm.inspectForm.handleOpinion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inspectForm,
                                              "handleOpinion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inspectForm.handleOpinion",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          (_vm.personInfo.physical_type == "从业体检" ||
                            _vm.personInfo.physicalType == "从业体检" ||
                            _vm.personInfo.physical_type == "健康体检" ||
                            _vm.personInfo.physicalType == "健康体检") &&
                          !_vm.isInspectionChange
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticClass: "choice",
                                      staticStyle: { width: "65px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleWordChoose("zjzybmc")
                                        },
                                      },
                                    },
                                    [_vm._v("体检结论 ")]
                                  ),
                                  _c("div", { staticClass: "txt" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "16px",
                                          position: "relative",
                                          top: "4px",
                                        },
                                      },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("体检结论"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "textareaResult" },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { maxRows: 2, minRows: 2 },
                                        },
                                        model: {
                                          value: _vm.inspectForm.conclusion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inspectForm,
                                              "conclusion",
                                              $$v
                                            )
                                          },
                                          expression: "inspectForm.conclusion",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          (_vm.personInfo.physical_type == "从业体检" ||
                            _vm.personInfo.physicalType == "从业体检" ||
                            _vm.personInfo.physical_type == "健康体检" ||
                            _vm.personInfo.physicalType == "健康体检") &&
                          !_vm.isInspectionChange
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticClass: "choice",
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleWordChoose("zjclyj")
                                        },
                                      },
                                    },
                                    [_vm._v("选 ")]
                                  ),
                                  _c("div", { staticClass: "txt" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "16px",
                                          position: "relative",
                                          top: "4px",
                                        },
                                      },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v("处理意见"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "textareaResult" },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { maxRows: 2, minRows: 2 },
                                        },
                                        model: {
                                          value: _vm.inspectForm.handleOpinion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inspectForm,
                                              "handleOpinion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inspectForm.handleOpinion",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.personInfo.physical_type == "职业体检" ||
                      _vm.personInfo.physicalType == "职业体检" ||
                      _vm.personInfo.physical_type == "放射体检" ||
                      _vm.personInfo.physicalType == "放射体检"
                        ? _c(
                            "Row",
                            { attrs: { gutter: 50 } },
                            [
                              _vm.isInspectionChange
                                ? _c("Col", { attrs: { span: "12" } }, [
                                    _c("div", { staticClass: "txt" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-size": "16px",
                                            position: "relative",
                                            top: "4px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("体检结果"),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "textareaResult" },
                                      [
                                        _c("Input", {
                                          attrs: {
                                            type: "textarea",
                                            autosize: {
                                              maxRows: 2,
                                              minRows: 2,
                                            },
                                          },
                                          model: {
                                            value: _vm.inspectForm.conclusion,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inspectForm,
                                                "conclusion",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "inspectForm.conclusion",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isInspectionChange
                                ? _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "choice",
                                          staticStyle: {
                                            bottom: "2px",
                                            right: "90px",
                                            width: "150px",
                                            display: "flex",
                                            "flex-direction": "row",
                                            "justify-content": "right",
                                          },
                                          attrs: { type: "primary" },
                                        },
                                        [
                                          _vm.isContraindication
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.isContraindication
                                                    ? _c(
                                                        "Button",
                                                        {
                                                          staticStyle: {
                                                            bottom: "2px",
                                                            width: "80px",
                                                            height: "20px",
                                                            display: "flex",
                                                            "flex-direction":
                                                              "row",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleWordChooseOccupation(
                                                                "zyjjz"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("职业禁忌证 ")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isDiseases
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.isDiseases
                                                    ? _c(
                                                        "Button",
                                                        {
                                                          staticStyle: {
                                                            bottom: "2px",
                                                            width: "80px",
                                                            height: "20px",
                                                            display: "flex",
                                                            "flex-direction":
                                                              "row",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleWordChooseDiseases(
                                                                "zyb"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("疑似职业病 ")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "Button",
                                        {
                                          staticClass: "choice",
                                          staticStyle: {
                                            bottom: "2px",
                                            width: "65px",
                                          },
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleWordChoose(
                                                "zjzybmc"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("主检结论 ")]
                                      ),
                                      _c("div", { staticClass: "txt" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "red",
                                              "font-size": "16px",
                                              position: "relative",
                                              top: "4px",
                                            },
                                          },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v("主检结论"),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "textareaResult" },
                                        [
                                          _c("Input", {
                                            staticStyle: {
                                              "background-color": "#fff",
                                              color: "#515a6e",
                                            },
                                            attrs: {
                                              type: "textarea",
                                              disabled: true,
                                              autosize: {
                                                maxRows: 2,
                                                minRows: 2,
                                              },
                                            },
                                            model: {
                                              value: _vm.conclusionNow,
                                              callback: function ($$v) {
                                                _vm.conclusionNow = $$v
                                              },
                                              expression: "conclusionNow",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isInspectionChange
                                ? _c("Col", { attrs: { span: "12" } }, [
                                    _c("div", { staticClass: "txt" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-size": "16px",
                                            position: "relative",
                                            top: "4px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("主检建议"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textareaResult",
                                        staticStyle: { position: "relative" },
                                      },
                                      [
                                        _c(
                                          "Button",
                                          {
                                            staticClass: "choice",
                                            staticStyle: { right: "-4px" },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleWordChoose(
                                                  "zjclyj"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选 ")]
                                        ),
                                        _c("Input", {
                                          attrs: {
                                            type: "textarea",
                                            autosize: {
                                              maxRows: 2,
                                              minRows: 2,
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.inspectForm.handleOpinion,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inspectForm,
                                                "handleOpinion",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "inspectForm.handleOpinion",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              !_vm.isInspectionChange
                                ? _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "choice",
                                          staticStyle: {
                                            bottom: "2px",
                                            right: "90px",
                                            width: "150px",
                                            display: "flex",
                                            "flex-direction": "row",
                                            "justify-content": "right",
                                          },
                                          attrs: { type: "primary" },
                                        },
                                        [
                                          _vm.isContraindication
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.isContraindication
                                                    ? _c(
                                                        "Button",
                                                        {
                                                          staticStyle: {
                                                            bottom: "2px",
                                                            width: "80px",
                                                            height: "20px",
                                                            display: "flex",
                                                            "flex-direction":
                                                              "row",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleWordChooseOccupation(
                                                                "zyjjz"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("职业禁忌证 ")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isDiseases
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.isDiseases
                                                    ? _c(
                                                        "Button",
                                                        {
                                                          staticStyle: {
                                                            bottom: "2px",
                                                            width: "80px",
                                                            height: "20px",
                                                            display: "flex",
                                                            "flex-direction":
                                                              "row",
                                                            "justify-content":
                                                              "center",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleWordChooseDiseases(
                                                                "zyb"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("疑似职业病 ")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "Button",
                                        {
                                          staticClass: "choice",
                                          staticStyle: {
                                            bottom: "2px",
                                            width: "65px",
                                          },
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleWordChoose(
                                                "zjzybmc"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("体检结论 ")]
                                      ),
                                      _c("div", { staticClass: "txt" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "red",
                                              "font-size": "16px",
                                              position: "relative",
                                              top: "4px",
                                            },
                                          },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v("体检结论"),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "textareaResult" },
                                        [
                                          _c("Input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: {
                                                maxRows: 2,
                                                minRows: 2,
                                              },
                                            },
                                            model: {
                                              value: _vm.inspectForm.conclusion,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.inspectForm,
                                                  "conclusion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "inspectForm.conclusion",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isInspectionChange
                                ? _c("Col", { attrs: { span: "12" } }, [
                                    _c("div", { staticClass: "txt" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-size": "16px",
                                            position: "relative",
                                            top: "4px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("处理意见"),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textareaResult",
                                        staticStyle: { position: "relative" },
                                      },
                                      [
                                        _c(
                                          "Button",
                                          {
                                            staticClass: "choice",
                                            staticStyle: { right: "-4px" },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleWordChoose(
                                                  "zjclyj"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选 ")]
                                        ),
                                        _c("Input", {
                                          attrs: {
                                            type: "textarea",
                                            autosize: {
                                              maxRows: 2,
                                              minRows: 2,
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.inspectForm.handleOpinion,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inspectForm,
                                                "handleOpinion",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "inspectForm.handleOpinion",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticClass: "choice",
                                      staticStyle: { bottom: "2px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleWordChoose(
                                            "zjqtjcycjg"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选 ")]
                                  ),
                                  _c("div", { staticClass: "txt" }, [
                                    _vm._v("其他疾病或异常描述"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "textareaException" },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { maxRows: 2, minRows: 2 },
                                        },
                                        model: {
                                          value:
                                            _vm.inspectForm
                                              .otherCheckAbnormalResults,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inspectForm,
                                              "otherCheckAbnormalResults",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inspectForm.otherCheckAbnormalResults",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticClass: "choice",
                                      staticStyle: { bottom: "2px" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleWordChoose(
                                            "qtjbycclyj"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选 ")]
                                  ),
                                  _c("div", { staticClass: "txt" }, [
                                    _vm._v("其他疾病异常处理意见"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "textareaException" },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { maxRows: 2, minRows: 2 },
                                        },
                                        model: {
                                          value:
                                            _vm.inspectForm.careerIllnessName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inspectForm,
                                              "careerIllnessName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inspectForm.careerIllnessName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.personInfo.physical_type == "从业体检" ||
                      _vm.personInfo.physicalType == "从业体检" ||
                      _vm.personInfo.physical_type == "健康体检" ||
                      _vm.personInfo.physicalType == "健康体检"
                        ? _c(
                            "Form",
                            {
                              ref: "inspectForm",
                              staticStyle: {
                                "margin-top": "30px",
                                padding: "10px 10px 10px 0",
                              },
                              attrs: {
                                model: _vm.inspectForm,
                                "label-width": 90,
                              },
                            },
                            [
                              _c(
                                "Row",
                                { attrs: { gutter: 24 } },
                                [
                                  _c(
                                    "Col",
                                    { attrs: { span: "5" } },
                                    [
                                      _c(
                                        "FormItem",
                                        { attrs: { label: "总检医生" } },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "150px",
                                              height: "30px",
                                            },
                                            attrs: {
                                              src: _vm.inspectForm
                                                .inspectionAutograph
                                                ? _vm.inspectForm.inspectionAutograph.indexOf(
                                                    "/dcm"
                                                  ) > -1
                                                  ? _vm.inspectForm
                                                      .inspectionAutograph
                                                  : "data:image/png;base64," +
                                                    _vm.inspectForm
                                                      .inspectionAutograph
                                                : _vm.doctorInfo.autograph,
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: "5" } },
                                    [
                                      _c(
                                        "Col",
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "总检日期：" } },
                                            [
                                              _c("DatePicker", {
                                                staticStyle: { width: "175px" },
                                                attrs: {
                                                  type: "datetime",
                                                  transfer: "",
                                                  placeholder: "请选择总检日期",
                                                },
                                                model: {
                                                  value:
                                                    _vm.inspectForm
                                                      .inspectionDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.inspectForm,
                                                      "inspectionDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "inspectForm.inspectionDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.personInfo.physical_type &&
                                  _vm.personInfo.physical_type.indexOf(
                                    "从业体检"
                                  ) !== -1
                                    ? _c(
                                        "Col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "FormItem",
                                            { attrs: { label: "健康证条件" } },
                                            [
                                              _c(
                                                "Select",
                                                {
                                                  attrs: { transfer: "" },
                                                  model: {
                                                    value:
                                                      _vm.inspectForm
                                                        .healthCertificateConditions,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.inspectForm,
                                                        "healthCertificateConditions",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "inspectForm.healthCertificateConditions",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.healthConditionList,
                                                  function (item, index) {
                                                    return _c(
                                                      "Option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: item.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "Col",
                                    {
                                      staticClass: "bottom-btn",
                                      attrs: { span: "8" },
                                    },
                                    [
                                      _vm.personInfo.is_pass == 3 ||
                                      _vm.personInfo.isPass == 3 ||
                                      _vm.personInfo.is_pass == 4 ||
                                      _vm.personInfo.isPass == 4 ||
                                      _vm.personInfo.is_pass == 5 ||
                                      _vm.personInfo.isPass == 5
                                        ? _c(
                                            "Button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                loading: _vm.inspectionLoading,
                                              },
                                              on: { click: _vm.addRecordClick },
                                            },
                                            [_vm._v("保存 ")]
                                          )
                                        : _vm.personInfo.is_pass == 4 ||
                                          _vm.personInfo.isPass == 4
                                        ? _c(
                                            "Button",
                                            {
                                              attrs: {
                                                type: "warning",
                                                loading: _vm.inspectionLoading,
                                              },
                                              on: { click: _vm.addRecordClick },
                                            },
                                            [_vm._v("已总检")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "Button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.personList.length > 0,
                                              expression:
                                                "personList.length > 0",
                                            },
                                          ],
                                          attrs: { type: "primary" },
                                          on: { click: _vm.reportPreview },
                                        },
                                        [_vm._v("报告总览")]
                                      ),
                                      _c(
                                        "Button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.personInfo.is_pass == 4 ||
                                                _vm.personInfo.isPass == 4 ||
                                                _vm.personInfo.is_pass == 5 ||
                                                _vm.personInfo.isPass == 5,
                                              expression:
                                                "(personInfo.is_pass == 4 || personInfo.isPass == 4) || (personInfo.is_pass == 5 || personInfo.isPass == 5)",
                                            },
                                          ],
                                          attrs: { type: "primary" },
                                          on: { click: _vm.reviewRecordClick },
                                        },
                                        [_vm._v(" 复查1 ")]
                                      ),
                                      _c(
                                        "Button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                (_vm.personInfo.physical_type ==
                                                  "健康体检" ||
                                                  _vm.personInfo.physicalType ==
                                                    "健康体检") &&
                                                (_vm.personInfo.is_pass == 4 ||
                                                  _vm.personInfo.isPass == 4 ||
                                                  _vm.personInfo.is_pass == 5 ||
                                                  _vm.personInfo.isPass == 5),
                                              expression:
                                                "(personInfo.physical_type == '健康体检' || personInfo.physicalType == '健康体检') && ((personInfo.is_pass == 4 || personInfo.isPass == 4) || (personInfo.is_pass == 5 || personInfo.isPass == 5))",
                                            },
                                          ],
                                          attrs: { type: "primary" },
                                          on: {
                                            click: _vm.auditingReportClick,
                                          },
                                        },
                                        [_vm._v(" 预览个人报告 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.personInfoLoading
                    ? _c("Spin", { attrs: { fix: "" } })
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "Col",
                { style: _vm.outSpan },
                [
                  _c(
                    "Card",
                    [
                      _c("no-data", {
                        staticStyle: {
                          height: "calc(100vh - 129px)",
                          overflow: "hidden",
                          display: "flex",
                          "flex-direction": "row",
                          "justify-content": "center",
                        },
                        attrs: { width: "150" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          staticClass: "result",
          attrs: { title: _vm.modelTitle, "mask-closable": false, width: 1300 },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "details-result",
              attrs: { value: "name1" },
              on: { "tab-click": _vm.resultClick },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "明细结果", name: "name1" } },
                [
                  _c(
                    "Card",
                    [
                      _c(
                        "Row",
                        { attrs: { type: "flex", justify: "space-between" } },
                        [
                          _c(
                            "Col",
                            {
                              staticStyle: { position: "relative" },
                              attrs: { span: "3" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { position: "relative" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tree-bar",
                                      style: { maxHeight: _vm.maxHeight },
                                    },
                                    [
                                      _vm._l(_vm.treeData, function (item, i) {
                                        return _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "12px",
                                              "margin-top": "7px",
                                            },
                                            attrs: {
                                              id: "trees" + item.officeId,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickSelectTree(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(_vm._s(item.title) + " "),
                                            _c("Icon", {
                                              attrs: {
                                                id: "icon" + item.officeId,
                                                type: "ios-alert-outline",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      _vm.treeLoading
                                        ? _c("Spin", {
                                            attrs: { size: "large", fix: "" },
                                          })
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "20" } },
                            [
                              _vm.officeName == "问诊科"
                                ? _c(
                                    "div",
                                    [
                                      _c("otherInfo", {
                                        ref: "otherInfo",
                                        attrs: {
                                          personInfo: _vm.personWZInfo,
                                          status: 1,
                                        },
                                      }),
                                      _vm.wzShow
                                        ? _c("Spin", {
                                            attrs: { size: "large", fix: "" },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.officeName != "问诊科"
                                ? _c(
                                    "div",
                                    {
                                      ref: "tab",
                                      style: {
                                        borderTop:
                                          _vm.tabsArr.length > 0
                                            ? ""
                                            : "1px dashed #ccc",
                                      },
                                    },
                                    [
                                      _vm.tabsArr.length > 0 &&
                                      _vm.officeName != "问诊科"
                                        ? _c(
                                            "el-tabs",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                "margin-top": "10px",
                                              },
                                              attrs: { type: "border-card" },
                                              on: {
                                                "tab-click": _vm.tabsClick,
                                              },
                                              model: {
                                                value: _vm.tabsValue,
                                                callback: function ($$v) {
                                                  _vm.tabsValue = $$v
                                                },
                                                expression: "tabsValue",
                                              },
                                            },
                                            _vm._l(
                                              _vm.tabsArr,
                                              function (item, index) {
                                                return _c(
                                                  "el-tab-pane",
                                                  {
                                                    key: index,
                                                    staticStyle: {
                                                      "margin-bottom": "10px",
                                                    },
                                                    attrs: {
                                                      label: item.groupItemName,
                                                      name: item.id,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "label",
                                                        },
                                                        slot: "label",
                                                      },
                                                      [
                                                        _c("Icon", {
                                                          attrs: {
                                                            id:
                                                              "elIcon" +
                                                              item.id,
                                                            type: "ios-alert-outline",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            item.groupItemName
                                                          )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    item.isFile == "是"
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "Row",
                                                              {
                                                                staticStyle: {
                                                                  height:
                                                                    "240px",
                                                                  border:
                                                                    "1px solid #DCDEE2",
                                                                  "border-top":
                                                                    "0",
                                                                  overflow:
                                                                    "auto",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "Col",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "justify-content":
                                                                          "center",
                                                                        "align-items":
                                                                          "center",
                                                                      },
                                                                    attrs: {
                                                                      span: "24",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "Table",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                loading:
                                                                                  _vm.loading,
                                                                                border:
                                                                                  "",
                                                                                columns:
                                                                                  _vm.getColumnsYX(),
                                                                                sortable:
                                                                                  "custom",
                                                                                data: _vm.baseProjectData,
                                                                                "row-class-name":
                                                                                  _vm.rowClassName,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "viewer",
                                                                              rawName:
                                                                                "v-viewer",
                                                                              value:
                                                                                {
                                                                                  movable: false,
                                                                                  zIndex: 7000,
                                                                                },
                                                                              expression:
                                                                                "{movable: false,zIndex:7000}",
                                                                            },
                                                                          ],
                                                                        staticStyle:
                                                                          {
                                                                            height:
                                                                              "80%",
                                                                            width:
                                                                              "100%",
                                                                            display:
                                                                              "flex",
                                                                            "justify-content":
                                                                              "center",
                                                                            "align-items":
                                                                              "center",
                                                                          },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.pacsData,
                                                                        function (
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "img",
                                                                            {
                                                                              key: index,
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "200px",
                                                                                  height:
                                                                                    "200px",
                                                                                  border:
                                                                                    "1px dashed #ccc",
                                                                                  "margin-right":
                                                                                    "20px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  src: item,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    item.isFile == "否" ||
                                                    item.isFile == null ||
                                                    item.isFile == ""
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c("Table", {
                                                              attrs: {
                                                                loading:
                                                                  _vm.loading,
                                                                border: "",
                                                                columns:
                                                                  _vm.resultColumn,
                                                                sortable:
                                                                  "custom",
                                                                data: _vm.baseProjectData,
                                                                "row-class-name":
                                                                  _vm.rowClassName,
                                                                height: "280",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "Form",
                                                      {
                                                        staticStyle: {
                                                          margin:
                                                            "20px 2px 10px",
                                                          "border-bottom": "0",
                                                        },
                                                        attrs: {
                                                          "label-width": 30,
                                                          inline: "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "Row",
                                                          {
                                                            staticStyle: {
                                                              "flex-wrap":
                                                                "nowrap",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Col",
                                                              {
                                                                staticStyle: {
                                                                  position:
                                                                    "relative",
                                                                },
                                                                attrs: {
                                                                  span: "10",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "14px",
                                                                        "font-weight":
                                                                          "600",
                                                                        "margin-bottom":
                                                                          "15px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "体检提醒或影像所见"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("Input", {
                                                                  staticClass:
                                                                    "result-textarea",
                                                                  attrs: {
                                                                    type: "textarea",
                                                                    placeholder:
                                                                      "输入多个以;分隔",
                                                                    autosize: {
                                                                      maxRows: 6,
                                                                      minRows: 6,
                                                                    },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.diagnoseTip,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "diagnoseTip",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.diagnoseTip",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c("Col", {
                                                              attrs: {
                                                                span: "2",
                                                              },
                                                            }),
                                                            _c(
                                                              "Col",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "16px",
                                                                },
                                                                attrs: {
                                                                  span: "10",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "14px",
                                                                        "font-weight":
                                                                          "600",
                                                                        "margin-bottom":
                                                                          "15px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "体检小结"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("Input", {
                                                                  staticClass:
                                                                    "result-textarea",
                                                                  attrs: {
                                                                    type: "textarea",
                                                                    placeholder:
                                                                      "输入多个以;分隔",
                                                                    autosize: {
                                                                      maxRows: 6,
                                                                      minRows: 6,
                                                                    },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.diagnoseSum,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "diagnoseSum",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.diagnoseSum",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "items-result",
                                                      },
                                                      [
                                                        _c(
                                                          "Row",
                                                          [
                                                            _c(
                                                              "Col",
                                                              {
                                                                attrs: {
                                                                  span: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "flex",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        "检查医生："
                                                                      ),
                                                                    ]),
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: item.checkSign
                                                                          ? item.checkSignPath &&
                                                                            item.checkSignPath.indexOf(
                                                                              "/dcm"
                                                                            ) >
                                                                              -1
                                                                            ? item.checkSignPath
                                                                            : item.checkSign.indexOf(
                                                                                "/dcm"
                                                                              ) >
                                                                              -1
                                                                            ? item.checkSign
                                                                            : "data:image/png;base64," +
                                                                              item.checkSign
                                                                          : _vm.checkDoctor,
                                                                        width:
                                                                          "150",
                                                                        height:
                                                                          "30",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "Col",
                                                              {
                                                                attrs: {
                                                                  span: "4",
                                                                },
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "检查日期：" +
                                                                      _vm._s(
                                                                        _vm.formatTime(
                                                                          item.checkDate
                                                                        )
                                                                      )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c("Col", {
                                                              attrs: {
                                                                span: "2",
                                                              },
                                                            }),
                                                            _c(
                                                              "Col",
                                                              [
                                                                _c(
                                                                  "ButtonGroup",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "flex",
                                                                        "justify-content":
                                                                          "center",
                                                                        "margin-left":
                                                                          "18px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "Button",
                                                                      {
                                                                        attrs: {
                                                                          type: "primary",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.handleViewTemplate,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "报告预览 "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.tabsArr.length <= 0 &&
                              _vm.officeName != "问诊科"
                                ? _c("no-data", {
                                    attrs: { width: "150", height: "150" },
                                  })
                                : _vm._e(),
                              _vm.groupShow
                                ? _c("Spin", {
                                    attrs: { size: "large", fix: "" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              false
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "历次结果", name: "name2" } },
                    [
                      _c(
                        "Card",
                        [
                          _c(
                            "Row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c(
                                "Col",
                                {
                                  staticStyle: { position: "relative" },
                                  attrs: { span: "3" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { position: "relative" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tree-bar",
                                          style: { maxHeight: _vm.maxHeight },
                                        },
                                        [
                                          _c("Tree", {
                                            ref: "tree",
                                            attrs: { data: _vm.treeData },
                                            on: {
                                              "on-select-change":
                                                _vm.selectTree,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.treeLoading
                                        ? _c("Spin", {
                                            attrs: { size: "large", fix: "" },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "Col",
                                { attrs: { span: "21" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "Row",
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: "10" } },
                                            [
                                              _c(
                                                "Form",
                                                { staticClass: "time-sty" },
                                                [
                                                  _c(
                                                    "FormItem",
                                                    {
                                                      attrs: {
                                                        label: "体检时间",
                                                      },
                                                    },
                                                    [
                                                      _c("DatePicker", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          type: "daterange",
                                                          placeholder: "请选择",
                                                        },
                                                        on: {
                                                          "on-change":
                                                            _vm.physicalTimeChange,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.physicalTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.physicalTime =
                                                              $$v
                                                          },
                                                          expression:
                                                            "physicalTime",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.officeName == "问诊科"
                                    ? _c(
                                        "div",
                                        [
                                          _vm.wzShow
                                            ? _c("Spin", {
                                                attrs: {
                                                  size: "large",
                                                  fix: "",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("otherInfo", {
                                            ref: "otherInfo",
                                            attrs: {
                                              personInfo: _vm.personWZInfo,
                                              status: 1,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.tabsArr.length > 0 &&
                                  _vm.officeName != "问诊科"
                                    ? _c(
                                        "el-tabs",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "10px",
                                          },
                                          attrs: { type: "card" },
                                          on: { "tab-click": _vm.tabsClick },
                                          model: {
                                            value: _vm.tabsValue,
                                            callback: function ($$v) {
                                              _vm.tabsValue = $$v
                                            },
                                            expression: "tabsValue",
                                          },
                                        },
                                        _vm._l(
                                          _vm.tabsArr,
                                          function (item, index) {
                                            return _c(
                                              "el-tab-pane",
                                              {
                                                key: index,
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                                attrs: {
                                                  label: item.groupItemName,
                                                  name: item.id,
                                                },
                                              },
                                              [
                                                item.isFile == "是"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "Row",
                                                          {
                                                            staticStyle: {
                                                              height: "240px",
                                                              border:
                                                                "1px solid #DCDEE2",
                                                              "border-top": "0",
                                                              overflow: "auto",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Col",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "justify-content":
                                                                    "center",
                                                                  "align-items":
                                                                    "center",
                                                                },
                                                                attrs: {
                                                                  span: "24",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "viewer",
                                                                          rawName:
                                                                            "v-viewer",
                                                                          value:
                                                                            {
                                                                              movable: false,
                                                                              zIndex: 7000,
                                                                            },
                                                                          expression:
                                                                            "{movable: false,zIndex:7000}",
                                                                        },
                                                                      ],
                                                                  },
                                                                  _vm._l(
                                                                    _vm.pacsData,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "img",
                                                                        {
                                                                          key: index,
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "200px",
                                                                              height:
                                                                                "200px",
                                                                              border:
                                                                                "1px dashed #ccc",
                                                                              "margin-right":
                                                                                "20px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              src: item,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.isFile == "否" ||
                                                item.isFile == null ||
                                                item.isFile == ""
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("Table", {
                                                          attrs: {
                                                            loading:
                                                              _vm.loading,
                                                            border: "",
                                                            columns:
                                                              _vm.resultColumn,
                                                            sortable: "custom",
                                                            data: _vm.baseProjectData,
                                                            "row-class-name":
                                                              _vm.rowClassName,
                                                            height: "280",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "Form",
                                                  {
                                                    staticStyle: {
                                                      margin: "20px 2px 10px",
                                                      "border-bottom": "0",
                                                    },
                                                    attrs: {
                                                      "label-width": 30,
                                                      inline: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "Row",
                                                      {
                                                        staticStyle: {
                                                          "flex-wrap": "nowrap",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "Col",
                                                          {
                                                            staticStyle: {
                                                              position:
                                                                "relative",
                                                            },
                                                            attrs: {
                                                              span: "10",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Button",
                                                              {
                                                                staticStyle: {
                                                                  position:
                                                                    "absolute",
                                                                  right: "-4px",
                                                                  bottom: "1px",
                                                                  "z-index":
                                                                    "10",
                                                                },
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleWordChoose(
                                                                        "zdtx"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("选 ")]
                                                            ),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "14px",
                                                                  "font-weight":
                                                                    "600",
                                                                  "margin-bottom":
                                                                    "15px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "体检提醒或影像所见"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("Input", {
                                                              staticClass:
                                                                "result-textarea",
                                                              attrs: {
                                                                type: "textarea",
                                                                placeholder:
                                                                  "输入多个以;分隔",
                                                                autosize: {
                                                                  maxRows: 6,
                                                                  minRows: 6,
                                                                },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.diagnoseTip,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "diagnoseTip",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.diagnoseTip",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c("Col", {
                                                          attrs: { span: "2" },
                                                        }),
                                                        _c(
                                                          "Col",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "16px",
                                                            },
                                                            attrs: {
                                                              span: "10",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Button",
                                                              {
                                                                staticStyle: {
                                                                  position:
                                                                    "absolute",
                                                                  right: "-4px",
                                                                  bottom: "1px",
                                                                  "z-index":
                                                                    "10",
                                                                },
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleWordChoose(
                                                                        "zdxj"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("选 ")]
                                                            ),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "14px",
                                                                  "font-weight":
                                                                    "600",
                                                                  "margin-bottom":
                                                                    "15px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "体检小结"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("Input", {
                                                              staticClass:
                                                                "result-textarea",
                                                              attrs: {
                                                                type: "textarea",
                                                                placeholder:
                                                                  "输入多个以;分隔",
                                                                autosize: {
                                                                  maxRows: 6,
                                                                  minRows: 6,
                                                                },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.diagnoseSum,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "diagnoseSum",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.diagnoseSum",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "items-result",
                                                  },
                                                  [
                                                    _c(
                                                      "Row",
                                                      [
                                                        _c(
                                                          "Col",
                                                          {
                                                            attrs: {
                                                              span: "8",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                },
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "检查医生："
                                                                  ),
                                                                ]),
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: item.checkSign
                                                                      ? item.checkSignPath &&
                                                                        item.checkSignPath.indexOf(
                                                                          "/dcm"
                                                                        ) > -1
                                                                        ? item.checkSignPath
                                                                        : item.checkSign.indexOf(
                                                                            "/dcm"
                                                                          ) > -1
                                                                        ? item.checkSign
                                                                        : "data:image/png;base64," +
                                                                          item.checkSign
                                                                      : _vm.checkDoctor,
                                                                    width:
                                                                      "150",
                                                                    height:
                                                                      "30",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "Col",
                                                          {
                                                            attrs: {
                                                              span: "8",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                "检查日期：" +
                                                                  _vm._s(
                                                                    _vm.formatTime(
                                                                      item.checkDate
                                                                    )
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.tabsArr.length <= 0 &&
                                  _vm.officeName != "问诊科"
                                    ? _c("no-data", {
                                        attrs: { width: "150", height: "150" },
                                      })
                                    : _vm._e(),
                                  _vm.groupShow
                                    ? _c("Spin", {
                                        attrs: { size: "large", fix: "" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  on: {
                    click: function ($event) {
                      _vm.modalVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            title: "阳性结果汇总",
            "mask-closable": false,
            "footer-hide": "",
            width: 1300,
          },
          model: {
            value: _vm.modalResult,
            callback: function ($$v) {
              _vm.modalResult = $$v
            },
            expression: "modalResult",
          },
        },
        [
          _c(
            "Row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "Col",
                { attrs: { span: "19" } },
                [
                  _c(
                    "Form",
                    {
                      ref: "positive",
                      attrs: { model: _vm.positive, "label-width": 95 },
                    },
                    [
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "5" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "姓名" } },
                                [
                                  _c("Input", {
                                    attrs: {
                                      placeholder: "请输入姓名",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.positive.personName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.positive,
                                          "personName",
                                          $$v
                                        )
                                      },
                                      expression: "positive.personName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "体检科室" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      attrs: { clearable: "" },
                                      model: {
                                        value: _vm.positive.officeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.positive,
                                            "officeId",
                                            $$v
                                          )
                                        },
                                        expression: "positive.officeId",
                                      },
                                    },
                                    _vm._l(_vm.departmentData, function (item) {
                                      return _c(
                                        "Option",
                                        {
                                          key: item.id,
                                          attrs: { value: item.id },
                                        },
                                        [_vm._v(_vm._s(item.sectionName))]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "7" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "体检日期" } },
                                [
                                  _c("DatePicker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      placeholder: "请选择体检日期",
                                    },
                                    on: { "on-change": _vm.selectDateRange },
                                    model: {
                                      value: _vm.selectDate,
                                      callback: function ($$v) {
                                        _vm.selectDate = $$v
                                      },
                                      expression: "selectDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "结果状态" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      on: {
                                        "on-change": _vm.resultStatusChange,
                                      },
                                      model: {
                                        value: _vm.resultStatus,
                                        callback: function ($$v) {
                                          _vm.resultStatus = $$v
                                        },
                                        expression: "resultStatus",
                                      },
                                    },
                                    [
                                      _c("Option", { attrs: { value: "0" } }, [
                                        _vm._v("不正常"),
                                      ]),
                                      _c("Option", { attrs: { value: "1" } }, [
                                        _vm._v("正常"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "5" } },
                [
                  _c(
                    "Row",
                    [
                      _c(
                        "Col",
                        [
                          _c(
                            "Button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.getSummaryPerson },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        [
                          _c(
                            "Button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        [
                          _c(
                            "Button",
                            {
                              attrs: { type: "success" },
                              on: { click: _vm.exportClick },
                            },
                            [_vm._v("导出Excel")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("Table", {
            ref: "summaryTable",
            attrs: {
              loading: _vm.summaryLoading,
              border: "",
              "max-height": "600",
              columns: _vm.summaryColumn,
              sortable: "custom",
              data: _vm.summaryData,
            },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function ({ row, index }) {
                  return [
                    _c(
                      "Button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          icon: "ios-eye-outline",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleViewClick(row, index)
                          },
                        },
                      },
                      [_vm._v("查看 ")]
                    ),
                    _c(
                      "Button",
                      {
                        attrs: { type: "info", size: "small" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.ignoreClick(row, index)
                          },
                        },
                      },
                      [_vm._v("忽略 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "Row",
            { staticClass: "page", attrs: { type: "flex", justify: "end" } },
            [
              _c("Page", {
                attrs: {
                  current: _vm.positive.pageNumber,
                  total: _vm.summarytotal,
                  "page-size": _vm.positive.pageSize,
                  "page-size-opts": [10, 15],
                  size: "small",
                  "show-total": "",
                  "show-elevator": "",
                  "show-sizer": "",
                  transfer: "",
                },
                on: {
                  "on-change": _vm.summaryChangePage,
                  "on-page-size-change": _vm.summaryChangePageSize,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("drawerReview", {
        attrs: {
          width: "400",
          modalTitle: _vm.title,
          type: _vm.type,
          inspectForm: _vm.inspectForm,
          inspectType:
            _vm.personInfo.physical_type || _vm.personInfo.physicalType,
          personInfo: _vm.personInfo,
          hazardFactors:
            _vm.personInfo.hazard_factors || _vm.personInfo.hazardFactors,
          hazardFactorsText:
            _vm.personInfo.hazard_factors_text ||
            _vm.personInfo.hazardFactorsText,
          isMultiple: _vm.isMultiple,
        },
        on: { getSelectedWordsReview: _vm.getSelectedWordsReview },
        model: {
          value: _vm.wordChooseShowReview,
          callback: function ($$v) {
            _vm.wordChooseShowReview = $$v
          },
          expression: "wordChooseShowReview",
        },
      }),
      _c(
        "Modal",
        {
          attrs: {
            title: "复查结果",
            "mask-closable": false,
            "footer-hide": "",
            width: 750,
          },
          model: {
            value: _vm.reviewPageShow,
            callback: function ($$v) {
              _vm.reviewPageShow = $$v
            },
            expression: "reviewPageShow",
          },
        },
        [
          _c(
            "Row",
            { staticStyle: { "margin-top": "-12px" }, attrs: { gutter: 20 } },
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "Button",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "6px",
                        bottom: "1px",
                        "z-index": "10",
                        height: "20px",
                        width: "65px",
                        padding: "0px",
                      },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleWordChooseReview("zjzybmc")
                        },
                      },
                    },
                    [_vm._v("体检结论 ")]
                  ),
                  _c("div", { staticClass: "txt" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "red",
                          "font-size": "16px",
                          position: "relative",
                          top: "4px",
                        },
                      },
                      [_vm._v("*")]
                    ),
                    _vm._v("复查体检结论"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "textareaResult" },
                    [
                      _c("Input", {
                        attrs: {
                          type: "textarea",
                          autosize: { maxRows: 4, minRows: 4 },
                        },
                        model: {
                          value: _vm.reviewData,
                          callback: function ($$v) {
                            _vm.reviewData = $$v
                          },
                          expression: "reviewData",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Col", { attrs: { span: "12" } }, [
                _c("div", { staticClass: "txt" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "red",
                        "font-size": "16px",
                        position: "relative",
                        top: "4px",
                      },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v("复查处理意见"),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "textareaResult",
                    staticStyle: { position: "relative" },
                  },
                  [
                    _c(
                      "Button",
                      {
                        staticStyle: {
                          position: "absolute",
                          right: "-4px",
                          bottom: "0px",
                          "z-index": "10",
                          height: "20px",
                          width: "20px",
                          padding: "0px",
                        },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleWordChooseReview("zjclyj")
                          },
                        },
                      },
                      [_vm._v("选 ")]
                    ),
                    _c("Input", {
                      attrs: {
                        type: "textarea",
                        autosize: { maxRows: 4, minRows: 4 },
                      },
                      model: {
                        value: _vm.reviewDataOpinion,
                        callback: function ($$v) {
                          _vm.reviewDataOpinion = $$v
                        },
                        expression: "reviewDataOpinion",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "Row",
            { staticStyle: { "margin-top": "12px" } },
            [
              _c(
                "div",
                {
                  staticClass: "txt",
                  staticStyle: {
                    "margin-top": "0.4vh",
                    "margin-right": "0.4vw",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "red",
                        "font-size": "16px",
                        position: "relative",
                        top: "4px",
                      },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v("复查总检日期:"),
                ]
              ),
              _c("DatePicker", {
                staticStyle: { width: "175px" },
                attrs: {
                  type: "datetime",
                  transfer: "",
                  placeholder: "请选择复查总检日期",
                },
                model: {
                  value: _vm.reviewInspectDate,
                  callback: function ($$v) {
                    _vm.reviewInspectDate = $$v
                  },
                  expression: "reviewInspectDate",
                },
              }),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "4" } },
                [
                  _c(
                    "Button",
                    {
                      staticStyle: { height: "30px", "margin-top": "10px" },
                      on: { click: _vm.closeReviewResult },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
              _c("Col", { attrs: { span: "16" } }),
              _c(
                "Col",
                {
                  staticStyle: { "text-align": "right", "margin-right": "0px" },
                  attrs: { span: "4" },
                },
                [
                  _c(
                    "Button",
                    {
                      staticStyle: {
                        height: "30px",
                        "margin-top": "10px",
                        "text-align": "center",
                      },
                      attrs: { type: "primary" },
                      on: { click: _vm.getReviewResult },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("preview-templateMX", {
        attrs: { personInfo: _vm.personInfo },
        model: {
          value: _vm.previewShowMX,
          callback: function ($$v) {
            _vm.previewShowMX = $$v
          },
          expression: "previewShowMX",
        },
      }),
      _c("review-record-list", {
        attrs: {
          tableDataReview: _vm.tableDataReview,
          personInfo: _vm.personInfo,
          conclusion: _vm.inspectForm.conclusion,
        },
        model: {
          value: _vm.reviewRecordShow,
          callback: function ($$v) {
            _vm.reviewRecordShow = $$v
          },
          expression: "reviewRecordShow",
        },
      }),
      _c("preview-template", {
        attrs: { status: _vm.status, TTemplateId: _vm.TTemplateId },
        model: {
          value: _vm.previewShow,
          callback: function ($$v) {
            _vm.previewShow = $$v
          },
          expression: "previewShow",
        },
      }),
      _c("drawer", {
        attrs: {
          width: "400",
          modalTitle: _vm.title,
          type: _vm.type,
          inspectForm: _vm.inspectForm,
          inspectType:
            _vm.personInfo.physical_type || _vm.personInfo.physicalType,
          personInfo: _vm.personInfo,
          hazardFactors:
            _vm.personInfo.hazard_factors || _vm.personInfo.hazardFactors,
          hazardFactorsText:
            _vm.personInfo.hazard_factors_text ||
            _vm.personInfo.hazardFactorsText,
          isMultiple: _vm.isMultiple,
        },
        on: { getSelectedWords: _vm.getSelectedWords },
        model: {
          value: _vm.wordChooseShow,
          callback: function ($$v) {
            _vm.wordChooseShow = $$v
          },
          expression: "wordChooseShow",
        },
      }),
      _c("drawerOccupation", {
        attrs: {
          width: "400",
          modalTitle: _vm.title,
          type: _vm.type,
          isMultiple: _vm.isMultiple,
          occupationalTaboo: _vm.occupationalTaboo,
          occupationalTabooCode: _vm.occupationalTabooCode,
          occupationalTabooNow: _vm.occupationalTabooNow,
        },
        on: { getSelectedWordsOccupation: _vm.getSelectedWordsOccupation },
        model: {
          value: _vm.wordChooseOccupationShow,
          callback: function ($$v) {
            _vm.wordChooseOccupationShow = $$v
          },
          expression: "wordChooseOccupationShow",
        },
      }),
      _c("drawerDiseases", {
        attrs: {
          width: "400",
          modalTitle: _vm.title,
          type: _vm.type,
          isMultiple: _vm.isMultiple,
          occupationalDiseases: _vm.occupationalDiseases,
          occupationalDiseasesCode: _vm.occupationalDiseasesCode,
          occupationalDiseasesNow: _vm.occupationalDiseasesNow,
        },
        on: { getSelectedWordsDiseases: _vm.getSelectedWordsDiseases },
        model: {
          value: _vm.wordChooseDiseasesShow,
          callback: function ($$v) {
            _vm.wordChooseDiseasesShow = $$v
          },
          expression: "wordChooseDiseasesShow",
        },
      }),
      _c("auditing-report", {
        attrs: {
          "type-status": _vm.typeStatus,
          "person-id": _vm.personInfo.id,
        },
        model: {
          value: _vm.auditingReportShow,
          callback: function ($$v) {
            _vm.auditingReportShow = $$v
          },
          expression: "auditingReportShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }